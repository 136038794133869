
.CourseRegistrationSearch {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  .FormWrapper {
    text-align: center;

    h2, .MuiSelect-select {
      text-align: left;
    }
  }

  .Form-buttons {
    margin-top: 35px;
  }
}

.CourseRegistrationDisplay {
  margin: 0 auto;
  max-width: 1000px;

  .back {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 25px;
    font-size: 0.95em;

    .Icon {
      margin-right: 10px;
    }
  }

  .update-instructions {
    margin-bottom: 30px;
    padding: 10px;
    background-color: $warning-color;
    color: $constrast-text-color;
    font-weight: 400;
  }

  h2 {
    font-weight: 500;

    span {
      text-transform: none;
      font-weight: 400;
    }
  }

  .field {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;

    span {
      min-width: 150px;
      font-weight: 500;
    }

    p, .value {
      flex: 1;
    }

    ul {
      margin: 0;
      margin-left: 20px;
      padding: 0;
    }
  }

  div.copy {
    display: block;
    margin-top: 7px;

    .copy-button {
      display: inline-block;
      font-weight: 400;
      cursor: pointer;
    }
    
    .Icon {
      font-size: 1.2em;
    }
  }

  .actions {
    margin-top: 35px;

    button {
      margin-right: 15px;
    }
  }

  .submission-message {
    margin-top: 20px;
  }
}

.CourseSelection, .CompleteCourseRegistration {
  margin: 0 auto;
  max-width: 700px;

  .LoadingMessage {
    text-align: center;
  }

  .current-selections {
    margin-bottom: 25px;

    .label {
      width: 150px;
      font-weight: 500;
      display: inline-block;
    }

    button {
      font-size: 0.9em;
      text-transform: none;
    }
  }

  .FormWrapper {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 35px;
  }

  .MuiSelect-select {
    text-align: left;
  }

  .checkboxes {
    margin-top: 35px;
    text-align: left;
  }
}