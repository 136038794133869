
.NavList {
  padding: 15px;
  max-width: 225px;
}

.NavList-user {
  padding-bottom: 15px;

  button {
    box-shadow: none;
    width: 100%;
  }

  .name {
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .email {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.NavList-links {
  margin: 10px 0px;

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;

    li {
      font-size: 1.05em;
      margin-bottom: 3px;
    }

    li.more {
      font-weight: 500;
    }
  }

  h3 {
    font-size: 1.05em;
    font-family: $primary-font;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
  }
}