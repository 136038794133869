
.App.classroom {
  #PageMessage {
    display: none;
  }
}

#PageMessage {
  padding: 20px 100px;
  color: $constrast-light-color;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;

  @include large-device { 
    padding: 20px 60px;
  }

  @include desktop-device { 
    padding: 20px 25px;
  }

  @include phone-device { 
    padding: 10px 15px;
    padding-top: 20px;
  }
}

#PageMessage.info {
  background-color: $info-color;
}

#PageMessage.success {
  background-color: $success-color;
}

#PageMessage.warning {
  background-color: $warning-color;
}

#PageMessage.error {
  background-color: $error-color;
}