
#Login-container {
  text-align: center;
}

#Login {
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;

  .LoadingMessage {
    margin-top: 30px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
