
.CourseMaps-content {
  margin: 0 auto;
  max-width: 1000px;

  h1 {
    margin-bottom: 55px;
  }

  .map-summary {
    margin-bottom: 55px;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 100%;

    span {
      min-width: 170px;
      font-weight: 500;
    }

    p {
      flex: 1;
    }
  }

  .more {
    margin-top: 15px;

    button {
      padding: 0px;
      color: $link-color;
      text-transform: none;
    }
  }
}

.CourseMaps-popup {  
  h2 {
    @include flex-row();
    align-items: center;

    span {
      flex: 1;
    }
  }
}

.ImportAttendance {
  .import-instructions {
    margin-top: 35px;
    padding: 10px;
    background-color: $warning-color;
    color: $constrast-text-color;
    font-weight: 400;
  }

  .completed-import {
    margin-top: 35px;

    a {
      font-size: 1.1em;
    }
  }
}

.ImportAttendance {
  margin: 0 auto;
  max-width: 700px;

  .LoadingMessage {
    text-align: center;
  }

  .current-selections {
    margin-bottom: 25px;

    .label {
      width: 150px;
      font-weight: 500;
      display: inline-block;
    }

    button {
      font-size: 0.9em;
      text-transform: none;
    }
  }

  .FormWrapper {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 35px;
  }

  .MuiSelect-select {
    text-align: left;
  }

  .checkboxes {
    margin-top: 35px;
    text-align: left;
  }
}



.Transcript {
  text-align: center;

  h1 {
    text-transform: none;
    margin-bottom: 25px;
  }

  .no-transcript {
    font-weight: 400;
    font-size: 1.1em;
  }
}

.Transcript-container {
  width: 70%;
  margin: 0 auto;
  text-align: center;

  @include medium-device {
    width: 100%;
  }
}

.Transcript-content {
  text-align: left;

  .return {
    button {
      padding: 0px;
      margin-top: 15px;
      text-transform: none;
      color: $link-color;
  
      .Icon {
        margin-right: 10px;
      }
    }
  }
}

.Transcript-content.record {
  .TranscriptHeading .certificates {
    display: none;
  }
}

.TranscriptHeading {
  margin-bottom: 15px;

  .no-name {
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 400;
  }

  .name {
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .email {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 15px;

    span {
      display: inline-block;
      font-weight: 400;
      margin-right: 10px;
    }
  }

  .certificates {
    font-weight: 400;
    color: $primary-color;

    .Icon {
      margin-right: 5px;
    }
  }
}

.Transcript-recordList {
  border-top: 1px solid $light-border-color;
  padding-top: 35px;
}

.Transcript-recordList.no-records {
  border-top: none;
}

.TranscriptRecords {
  margin-bottom: 55px;

  h3 {
    font-size: 1.1em;
    font-weight: 500;
    font-family: $primary-font;
    margin-bottom: 20px;
  }

  .record {
    margin-bottom: 35px;
  }

  Button.title {
    margin-bottom: 3px;
    padding: 0px;
    color: $link-color;
    text-transform: none;
    font-size: 1.05em;

    .Icon {
      margin-right: 5px;
    }
  }

  .details {
    margin-top: -2px;

    @include flex-row();
    align-items: center;

    @include tablet-device {
      display: block;
      margin-top: 0px;
    }
  }

  .progress {
    width: 250px;
    margin-right: 30px;
    font-size: 0.95em;

    @include tablet-device {
      margin-bottom: 2px;
    }

    .label {
      font-weight: 500;
      margin-right: 10px;
    }

    .text {
      font-weight: 400;
    }

    .error {
      display: inline-block;
      margin-top: 3px;
      color: $error-color;
      font-size: 1em;
    }
  }

  .actions {
    .label {
      font-weight: 500;
      font-size: 0.95em;
      margin-right: 15px;
    }

    button {
      padding: 0px;
      text-transform: none;
      margin-right: 25px;
      font-size: 0.95em;
      color: $secondary-color;
    }
  }

  .description, .date {
    margin-bottom: 5px;

    .label {
      font-weight: 500;
      font-size: 0.95em;
      margin-right: 10px;
    }

    .text {
      font-weight: 400;
      font-size: 0.95em;
    }
  }
}

.TranscriptRecords.no-records {
  display: none;
}

.TranscriptRecordDetails {
  h3, h4 {
    text-transform: none;
  }

  .LoadingMessage {
    font-size: 1.1em;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 100%;

    span {
      min-width: 150px;
      font-weight: 500;
    }

    p, .value {
      flex: 1;
    }

    ul {
      margin: 0;
      margin-left: 20px;
      padding: 0;
    }
  }

  .field.assessments {
    button {
      padding: 0px;
      color: $link-color;
      font-size: 0.95em;
      text-transform: none;
    }
  }

  .TranscriptRecordDetails-general {
    .actions {
      margin-top: 25px;

      button {
        padding: 5px;
        font-size: 0.95em;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .TranscriptRecordDetails-registration {
    margin-top: 55px;
  }
}

.TranscriptRecordProgress {
  margin-top: 55px;

  h3 {
    margin-bottom: 20px;
  }

  .lesson-heading {
    @include flex-row();
    align-items: center;

    h4 {
      font-weight: 500;
      flex: 1;
    }

    .Icon {
      margin-right: 7px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0px;
    padding-left: 22px;
    margin-bottom: 30px;

    li {
      @include flex-row();
      align-items: center;
      margin-bottom: 5px;
      font-weight: 400;

      .Icon {
        margin-right: 7px;
      }

      span {
        flex: 1;
      }

      button {
        padding: 0px;
        text-transform: none;
        color: $link-color;
        margin-right: 10px;
      }
    }
  }

  .exams {
    margin-bottom: 50px;

    .Icon {
      margin-right: 7px;
    }

    button {
      padding: 0px;
      text-transform: none;
      color: $link-color;
      margin-right: 10px;
    }
  }

  .actions {
    .changes {
      display: inline-block;
      font-weight: 500;
      margin-bottom: 20px;
      background-color: $warning-color;
      color: $constrast-text-color;
      padding: 10px;
    }

    .buttons {
      display: block;

      button {
        margin-right: 15px;
      }
    }
  }
}

.TranscriptRecordAssessments-popup {
  min-height: 60px;
  padding-left: 10px;
  padding-top: 5px;

  h2 {
    @include flex-row();
    align-items: center;

    span {
      flex: 1;
    }
  }

  .LoadingMessage {
    font-size: 0.95em;
  }

  .assessment-list {
    padding: 0px 0px 30px 0px;
  }

  .assessment-record {
    margin-bottom: 2px;

    @include small-device {
      margin-bottom: 10px;
    }
  }

  .item-title {
    display: inline-block;
    width: 175px;
    margin-right: 5px;
    font-weight: 400;

    @include small-device {
      width: 60%;
    }
  }

  .score {
    display: inline-block;
    width: 75px;
    margin-right: 5px;
    font-weight: 400;

    @include small-device {
      width: 30%;
    }
  }

  .no-records {
    font-weight: 400;
  }

  .error {
    font-weight: 400;
    color: $error-color;
  }
}

.TranscriptRecords-popup, .TranscriptRecordDetails-popup {
  font-weight: 400;

  .what {
    margin-bottom: 10px;
  }

  .course-title {
    font-weight: 500;
    margin-bottom: 35px;
  }

  .consequences {
    margin-bottom: 10px;
  }

  .sure {
    font-weight: 500;
    color: $secondary-color;
  }

  .content-actions {
    .keep {
      margin-right: 20px;

      @include phone-device {
        argin-right: 0px;
      }
    }

    .remove {
      color: $secondary-color;
    }
  }
}