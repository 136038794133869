@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin centered-flex-row {
  @include flex-row();
  align-items: center;
  justify-content: center;
}

@mixin spaced-flex-row {
  @include flex-row();
  justify-content: space-between;
}

@mixin centered-flex-col {
  @include flex-col();
  align-items: center;
  justify-content: center;
}

@mixin uppercase($size) {
  font-family: $primary-font;
  font-size: $size;
  text-transform: uppercase;
}

@mixin mui-no-panel-decoration {
  box-shadow: none;
  border: none;
}

@mixin large-device {
  @media only screen and (max-width: #{$breakpoint-large}) {
    @content;
  }
}

@mixin desktop-device {
  @media only screen and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin medium-device {
  @media only screen and (max-width: #{$breakpoint-medium}) {
    @content;
  }
}

@mixin tablet-device {
  @media only screen and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin small-device {
  @media only screen and (max-width: #{$breakpoint-small}) {
    @content;
  }
}

@mixin phone-device {
  @media only screen and (max-width: #{$breakpoint-phone}) {
    @content;
  }
}

@mixin ie-specific {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
    @content;
  }
}
