
.ManageCommunity {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  .MuiSelect-select {
    text-align: left;
  }
}

.CommunityDetails {
  text-align: left;

  .back {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 25px;
    font-size: 0.95em;

    .Icon {
      margin-right: 10px;
    }
  }

  h2 {
    margin-bottom: 5px;
  }

  p.instructions {
    margin-bottom: 20px;
    font-weight: 400;
  }

  .section {
    margin-bottom: 35px;

    h3 {
      margin-bottom: 15px;
      font-size: 1em;
    }
  }

  .field {
    margin-bottom: 10px;
    @include flex-row();

    .label {
      font-weight: 500;
      width: 200px;
    }

    .value {
      flex: 1;
    }

    span {
      display: block;
    }
  }

  .field.editable {
    cursor: pointer;

    .label {
      text-decoration: underline;
      color: $link-color;
    }
  }

  .notes {
    cursor: pointer;
  }

  .actions {
    margin-top: 55px;
    @include small-device {
      display: flex;
      flex-direction: column;
    }

    button {
      margin-right: 20px;

      @include small-device {
        width: 70%;
        margin-top: 15px;
        margin-right: 0px;
      }
    }
  }

  .deleted-message {
    font-size: 1.1em;
    font-weight: 400;
  }
}

.CommunityMembers {
  max-width: 550px;

  ul {
    padding-left: 0px;
    margin-left: 0px;
    list-style-type: none;
  }

  li {
    margin-bottom: 15px;
    @include flex-row();
    align-items: center;

    .name {
      flex: 1;
    }

    .action {
      width: 65px;
    }
  }
}

.CommunityEdit {
  .Form {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 40px;
  }

  .delete {
    margin-top: 20px;
  }
}