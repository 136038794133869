
.MagdalaRegistration {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  .community-name {
    text-align: left;
    
    p {
      margin-bottom: 35px;
      font-size: 1.0em;
    }

    strong {
      display: block;
      margin-top: 5px;
      font-weight: 500;
    }
  }

  .FormWrapper {
    text-align: center;

    .MuiSelect-select {
      text-align: left;
    }
  }

  .Form-buttons {
    margin-top: 35px;
  }

  .dashboard-link {
    font-size: 1.1em;
    font-weight: 400;
    text-decoration: none;
  }

  .current-selections {
    text-align: left;
    margin-bottom: 25px;
      
    .label {
      width: 150px;
      font-weight: 500;
      display: inline-block;
    }
    
    button {
      font-size: 0.9em;
      text-transform: none;
    }
  }
}


.CommunityRegistration {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  .FormWrapper {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 35px;
  }

  .dashboard-link {
    font-size: 1.1em;
    font-weight: 400;
    text-decoration: none;
  }
}