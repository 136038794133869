
.CacheClear {
  margin: 0 auto;
  max-width: 700px;

  .FormWrapper {
    text-align: center;

    .MuiSelect-select {
      text-align: left;
    }
  }

  .Form-buttons {
    margin-top: 35px;
  }
}

.ManageMarketingTags {
  margin: 0 auto;
  max-width: 700px;

  .LoadingMessage {
    text-align: center;
  }

  .ManageMarketingTags-button {
    margin-top: 25px;
  }

  .ManageMarketingTags-list {
    margin-top: 35px;

    p {
      font-weight: 500;
      margin-bottom: 25px;
    }

    .tag {
      @include flex-row();
      margin-bottom: 10px;

      .tagId {
        margin-right: 20px;
      }
    }
  }
}