@import './styles/variables';
@import './styles/mixins';
@import './styles/foundation';

@import './styles/components/common';
@import './styles/components/layout/Header';
@import './styles/components/layout/NavMenu';
@import './styles/components/layout/PageMessage';
@import './styles/components/Dashboard';
@import './styles/components/Login';
@import './styles/components/ContentManagement';
@import './styles/components/CourseManagement';
@import './styles/components/CourseRegistration';
@import './styles/components/MagdalaCommunities';
@import './styles/components/MagdalaRegistration';
@import './styles/components/MemberProfile';
@import './styles/components/Notifications';
@import './styles/components/Report';


main {
  margin: 0 auto;
  padding: 50px 100px;
  min-height: 350px;
  max-width: 1240px;
  text-align: left;

  @include large-device { 
    padding: 50px 60px;
  }

  @include desktop-device {
    padding: 40px 25px;
  }

  @include phone-device { 
    padding: 30px 15px;
  }

  .MainContent-container {
    text-align: center;
  }
}

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

.App.home {
  main {
    padding: 0px;
    margin: 0px;
    max-width: 100%;
  }
}

.StandardSystemPopup {
  h2.MuiTypography-root {
    @include flex-row();

    span {
      flex: 1;
    }

    button {
      margin-top: -8px;
      margin-right: -20px;
    }
  }
}

#NotFound, #DeniedAccess {
  text-align: center;
  
  .NotFound-aesthetic, .DeniedAccess-aesthetic {
    display: flex;
    justify-content: center;
    align-items: center;

    .Icon {
      margin: 0px;
      margin-right: 20px;

      svg {
        color: $secondary-color;
      }
    }
  }

  .NotFound-aesthetic-content, .DeniedAccess-aesthetic-content {
    padding-bottom: 5px;
    color: $secondary-color;

    .NotFound-404, .DeniedAccess-403 {
      font-size: 4em;
    }

    .NotFound-message, .DeniedAccess-message {
      font-size: 1.5em;
      margin-top: -10px;
    }
  }

  .NotFound-descriptive, .DeniedAccess-descriptive {
    margin-top: 30px;
    font-size: 2em;
    font-weight: 300;
  }
}

.NotFound-migrated-suggestion {
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: 400;
}
