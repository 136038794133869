
.SendNotifications, .ManageEmailCategories {
  text-align: center;

  .SendNotifications-content, .ManageEmailCategories-container {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }

  .dashboard-link {
    font-size: 1.1em;
    font-weight: 400;
    text-decoration: none;
  }
}

#SendNotificationsForm {
  .FormField-control {
    text-align: left;
  }

  .Form-buttons {
    margin-top: 60px;
  }

  .category-help {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: 400;
    color: $info-color;
  }

  .message-label {
    text-align: left;
    font-size: 1.15em;
    font-weight: 400;
    margin-bottom: -20px;
    padding-bottom: 0px;
    margin-top: 40px;
  }

  .message-length {
    font-weight: 500;
    text-align: left;
    margin-top: 5px;
  }

  .message-length.long {
    color: $error-color;
  }

  textarea {
    height: 80px !important;
  }
}

.ManageEmailCategories-container {
  .LoadingMessage {
    margin-bottom: 45px;
  }
}

.ManageEmailCategories-content {
  text-align: left;

  .heading {
    @include flex-row();
    margin-bottom: 60px;
    
    h2 {
      flex: 1;
      margin-top: 8px;
    }
  }

  .ManageEmailCategories-list {
    .category-heading {
      @include flex-row();
    }

    h3 {
      flex: 1;
      text-transform: none;
      margin-bottom: 15px;
    }

    .order {
      font-weight: 400;
    }

    .ManageEmailCategories-category {
      margin-bottom: 40px;
    }

    .description {
      @include flex-row();

      p {
        flex: 1;
        margin-right: 20px;
      }
    }

    button {
      padding: 0px;
    }
  }

  .no-categories {
    font-weight: 400;
  }

  .FormField-control {
    text-align: left;
  }

  .Form-buttons {
    margin-top: 60px;
  }

  textarea {
    height: 80px !important;
  }
}

.ManageEmailCategories-warning {
  font-weight: 400;
  margin-bottom: 20px;
}