$primary-font:    'Roboto', sans-serif;

$h1-default-size: 1.5em;
$h2-default-size: 1.25em;
$h3-default-size: 1.10em;

$primary-color:       #102B4E;
$primary-dark:        #041934;
$primary-alt-color:   #26436A;
$primary-alt-hover:   #3E4F7D;
$primary-light-color: #E1C585;
$primary-light-alt:   #D0932C;
$primary-contrast:    #466388;
$secondary-color:     #98272b;
$secondary-hover:     #CB583E;
$tertiary-color:      #D4CBB6;
$tertiary-light-color:#F9F4E8;
$app-bar-color:       $primary-color;
$heading-color:       #3C3C3C;
$info-color:          #1976d2;
$success-color:       #43A047;
$warning-color:       #FFA000;
$error-color:         #D32F2F;
$notification-color:  #FFBC35;
$close-color:         $secondary-color;
$link-color:          #2498D4;
$success-text-color:  #E3FFE5;
$error-text-color:    #FFFFFF;
$base-text-color:     #545454;
$darker-text-color:   #3C3C3C;
$disabled-text-color:   #9C9C9C;
$constrast-text-color:      #000000;
$constrast-light-color:     #FFFFFF;
$invisible-background:      #FFFFFF;
$offset-background-color:   #F1F1F1;
$med-border-color:          #545454;
$light-border-color:        #D0D0D0;
$light-bg-color:            #EFEFEF;
$form-offset-color:         #E1C585;
$form-offset-input-color:   #FBF2DB;
$header-menu-hover:         #FFEABC;
$header-menu-icon:          $primary-contrast;
$header-menu-icon-hover:    #6B83A2;
$header-menu-icon-bg:       $primary-alt-color;
$section-heading-header:    #E1C585;
$section-heading:           #FBF2DB;
$transparent-overlay:       rgba(0, 0, 0, 0.6);
$overlay-color:             #000000;  
$overlay-light-color:       #FFFFFF; 
$progress-color:            #FF0000;
$dark-button-color:         #3C3C3C;
$dark-button-hover:         #545454;    
$default-feature-bg:        #CECECE;


$breakpoint-large: 1200px;
$breakpoint-desktop: 1023px;
$breakpoint-medium: 959px;
$breakpoint-tablet: 767px;
$breakpoint-small: 600px;
$breakpoint-phone: 420px;
$breakpoint-small-phone: 350px;