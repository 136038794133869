@import './common/form';
@import './common/Icon';
@import './common/PopMessage';


.Heading { font-weight: 400; }
.Heading.weight-subtitle { font-weight: 300; }
h1.Heading { font-size: $h1-default-size; }
h2.Heading { font-size: $h2-default-size; }
h3.Heading { font-size: $h3-default-size; }


.LinkButton {
  .Icon {
    margin-right: 5px;
    line-height: 1em;
  }
}


.Banner {
  .Banner-message {
    @include centered-flex-row();
    margin: 0 auto;
    padding: 15px;

    h1 {
      margin: 0px;
      font-size: 1.75em;

      @include medium-device {
        font-size: 1.5em;
      }
    }

    h2 {
      @include small-device {
        font-size: 1.15em;
      }
    }

    .Banner-icon {
      margin-right: 20px;

      @include medium-device {
        margin-right: 5px;
      }
    }
  }

  .Banner-message.success {
    background-color: $success-color;
    color: $success-text-color;
    h1 { color: $success-text-color; }
  }

  .Banner-message.error {
    background-color: $error-color;
    color: $error-text-color;

    h1 { color: $error-text-color; }
  }
}


.ConditionalMessage {
  .ConditionalMessage-text.error {
    background-color: $error-color;
    color: $error-text-color;
    padding: 15px;
    border-radius: 5px;
  }

  .ConditionalMessage-text.notification {
    background-color: $notification-color;
    padding: 15px;
    border-radius: 5px;
    color: $constrast-text-color;
  }
}


.ContactErrorMessage-wrapper {
  padding: 15px;

  .ConditionalMessage-text.error {
    background-color: $invisible-background;
    color: inherit;
  }
}

.Form .ContactErrorMessage .ConditionalMessage-text.error {
  background-color: $invisible-background;
  color: inherit;
}


.LoadingMessage {
  font-size: 1.5em;

  span {
    margin-left: 10px;
  }
}

.LoadingMessage.small { font-size: 1.25em; }
.LoadingMessage.large { font-size: 1.5em; }


.NotificationMessage {
  .NotificationMessage-wrapper {
    margin-top: 20px;
    padding: 30px;

    .Icon {
      margin-bottom: 10px;
    }

    h2 {
      margin-top: 10px; 
      margin-bottom: 20px;
    }

    p {
      margin-top: 10px; 
      margin-bottom: 10px;
    }

    button {
      .Icon {
        margin-right: 10px;
      }

      span {
        align-items: normal;
      }
    }
  }
}


.PageTitle {
  text-align: center;
  margin-bottom: 80px;

  h1 {
    color: $primary-color;
    font-weight: 300;
    font-size: 2.25em;
    letter-spacing: 2px;
    padding: 5px 0px;

    @include medium-device {
      font-size: 2.15em;
    }

    @include small-device {
      font-size: 1.75em;
    }
  }

  h2 {
    font-weight: 400;
    padding-left: 2px;
    text-transform: none;
    letter-spacing: 1.5px;

    @include small-device {
      font-size: 1.15em;
    }
  }
}


.PanelContent {
  > div { 
    margin: 0 auto; 
  }

  .padded {
    padding: 30px 30px 60px 30px;
  }

  .padded.fill-small {
    @include small-device {
      padding: 0px 0px 60px 0px;
    }
  }

  .single {
    max-width: 660px;
  }

  .double {
    max-width: 1140px;
  }
}


.MemberSearch {
  margin: 0 auto;
  max-width: 700px;

  .FormWrapper {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 35px;
  }

  .MuiInputBase-root {
    width: 100%;
  }
}

#MemberSearch-form {
  .MuiSelect-root {
    text-align: left;
  }
}

.MemberSelection {
  margin: 0 auto;
  max-width: 700px;
  text-align: left;

  .back {
    button {
      padding: 0px;
      margin-bottom: 20px;
      font-size: 0.95em;
    }

    .Icon {
      margin-right: 10px;
    }
  }

  .heading {
    margin-bottom: 35px;
  }

  .results {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 1.15em;
  }

  .results-limited {
    font-weight: 300;
  }

  .member {
    margin-bottom: 15px;
    font-size: 1.05em;
  }

  .email {
    display: inline-block;
    width: 350px;
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
  }
}

.ConfirmationDialog {
  .title h2 {
    font-size: 1.2em;
  }
}

.JobStatusMessage {
  text-align: left;

  .heading-message {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .JobStatusMessage-progress {
    height: 10px;
    position: relative;
    margin-bottom: 55px;
  
    .progress {
      height: 10px;
      background-color: $primary-color;
      position: absolute;
    }

    .progress-data {
      padding-top: 15px;
    }
  
    .background {
      width: 100%;
      height: 10px;
      background-color: $light-border-color;
      position: absolute;
    }
  }
}