
.Report {
  max-width: 700px;
  margin: 0 auto;
  text-align: left;

  .LoadingMessage {
    text-align: center;

    span.message {
      font-size: 1em;
      color: $darker-text-color;
    }
  }

  h2 {
    margin-bottom: 5px;
  }

  h3 {
    text-transform: none;
    margin-bottom: 10px;
    font-size: 1.1em;
    font-weight: 500;
  }

  h4 {
    text-transform: none;
    margin-bottom: 5px;
    font-size: 1em;
    font-weight: 500;
  }

  .message {
    margin-bottom: 25px;
  }

  .filters {
    margin-bottom: 35px;
  }

  .course-filters, .status-filters, .track-filters {
    margin-bottom: 15px;
  }

  .course-list {
    @include flex-row();
    flex-wrap: wrap;
    margin-bottom: 35px;

    .course-list-item {
      width: 45%;
      margin-bottom: 10px;
      margin-right: 5%;
      font-weight: 400;
      cursor: pointer;
    }
  }

  .two-cols {
    @include flex-row();
    margin-bottom: 35px;

    .status-list, .track-list {
      width: 45%;
    }

    .status-list {
      margin-right: 5%;
    }
  }

  .status-list-item, .track-list-item {
    margin-bottom: 10px;
    font-weight: 400;
    cursor: pointer;
  }

  .selected {
    color: $secondary-color;
  }

  .download {
    .message {
      font-weight: 400;
      font-size: 1.1em;
      color: $info-color;
    }
  }
}

.DownloadReport {
  .message {
    font-weight: 400;
    font-size: 1.1em;
    color: $info-color;
  }
}