
.Dashboard {
  display: flex;
  flex-wrap: wrap;

  .section {
    width: 25%;
    min-width: 260px;
    margin-bottom: 45px;

    @include desktop-device { 
      width: 33%;
    }

    @include tablet-device { 
      width: 50%;
    }

    @include phone-device { 
      width: 100%;
    }
  }

  h3 {
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 3px;
    }
  }

  a {
    text-decoration: none;
    font-weight: 400;
  }
}