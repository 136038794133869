#HeaderOffset {
  display: block;
  height: 64px;

  @include tablet-device {
    height: 54px;
  }
}

header.AppHeader {
  background-color: $app-bar-color;
  color: $constrast-light-color;

  > div {
    padding-left: 5px;
    padding-right: 5px;

    @include tablet-device { 
      min-height: inherit;
    }
  }
}

.AppHeader-logo {
  display: block;
  text-align: left;
  min-width: 170px;
  flex: 1;

  img {
    max-width: 150px;
    height: auto;
    border: 0px;
    outline: none;

    @include tablet-device { 
      max-width: 120px;
    }
  }
}

.AppHeader-navigation {
  @include flex-row();
}

.AppHeader-user {
  @include flex-col();
  text-align: right;
  margin-right: 15px;
  margin-top: 7px;

  @include small-device {
    display: none;
  }

  .title {
    font-size: 1.1em;
    font-weight: 500;
  }

  .user {
    font-weight: 300;
  }
}

.AppHeader-menuButton {
  color: $tertiary-light-color;
  font-size: 3em;
  cursor: pointer;
}