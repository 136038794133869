
.ManageMember, .MemberRegistration, .MemberDonations {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  .MuiSelect-select {
    text-align: left;
  }
}

.MemberDetails {
  text-align: left;

  .back {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 25px;
    font-size: 0.95em;

    .Icon {
      margin-right: 10px;
    }
  }

  .MemberUpdateResults {
    margin-bottom: 45px;
    padding: 10px;
    border: 1px solid $med-border-color;
    border-radius: 5px;

    .error {
      font-weight: 500;
      color: $error-color;
      margin-bottom: 25px;
    }

    h3 {
      margin-bottom: 7px;
      text-transform: none;
      font-size: 1.1em;
    }

    .messages {
      margin-bottom: 35px;

      .message {
        margin-bottom: 10px;
        font-weight: 400;
        color: $info-color;
      }
    }

    .messages.error .message {
      color: $base-text-color;
    }

    .completed {
      margin-bottom: 35px;
    }

    .merge {
      font-weight: 500;
      font-size: 1.1em;
      margin-bottom: 20px;
      margin-top: 45px;
    }

    .show-merge button {
      margin-right: 15px;
    }

    .hide-merge button {
      padding: 0px;
      min-width: 0px;
    }
  }

  h2 {
    margin-bottom: 5px;
  }

  p.instructions {
    margin-bottom: 20px;
    font-weight: 400;
  }

  .section {
    margin-bottom: 35px;

    h3 {
      margin-bottom: 15px;
      font-size: 1em;
    }
  }

  .field {
    margin-bottom: 10px;
    @include flex-row();

    .label {
      font-weight: 500;
      width: 200px;
    }

    .value {
      flex: 1;
    }

    span {
      display: block;
    }
  }

  .field.editable {
    cursor: pointer;

    .label {
      text-decoration: underline;
      color: $link-color;
    }
  }

  .identity {
    margin-bottom: 5px;
  }

  .actions {
    margin-top: 45px;
    @include small-device {
      display: flex;
      flex-direction: column;
    }

    button {
      margin-right: 20px;

      @include small-device {
        width: 70%;
        margin-top: 15px;
        margin-right: 0px;
      }
    }
  }

  .actions.delete {
    margin-top: 65px;
    border-top: 1px solid $light-border-color;
    padding-top: 15px;

    p {
      margin-bottom: 15px;
      font-weight: 400;
      color: $secondary-color;
    }

    button {
      background-color: $error-color;
      color: $constrast-light-color;
    }
  }

  .member-deleted {
    font-weight: 400;
    font-size: 1.2em;

    strong {
      font-weight: 500;
      color: $secondary-color;
    }
  }
}

.MemberEdit, .MemberRegistration {
  .Form {
    text-align: center;
  }

  .Form-buttons {
    margin-top: 40px;
  }

  .delete {
    margin-top: 20px;
  }
}

.MemberDeletionConfirmation {
  .instructions {
    margin-bottom: 20px;

    strong {
      font-weight: 500;
    }
  }

  .instructions.consequences strong {
    color: $error-color;
  }

  .MuiInput-root {
    width: 92%;
  }
}

.MemberDonations {
  .back {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 25px;
    font-size: 0.95em;
    text-align: left;

    .Icon {
      margin-right: 10px;
    }
  }

  h2 {
    text-align: left;
    margin-bottom: 55px;
  }

  h3 {
    text-transform: none;
    font-weight: 400;
    margin-bottom: 25px;
  }

  .none {
    font-weight: 400;
  }

  .recurring-list {
    margin-top: 45px;
    margin-bottom: 65px;
    text-align: left;
  
    .recurring-donation {
      margin-bottom: 40px;
  
      .amount {
        font-size: 1em;
        margin-bottom: 5px;
        font-weight: 600;
      }
  
      .dates {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
  
        @include small-device {
          display: block;
  
          > div {
            margin-bottom: 5px;
          }
        }
  
        .created {
          margin-right: 30px;
        }
  
        span {
          margin-right: 10px;
          font-weight: 500;
        }
      }
    }
  }

  .scheduled-list {
    margin-top: 45px;
    margin-bottom: 65px;
    text-align: left;

    .scheduled-donation {
      .amount {
        display: inline-block;
        width: 150px;
        font-size: 1em;
        margin-bottom: 15px;
        font-weight: 600;
      }

      .date {
        display: inline-block;
        
        span {
          font-weight: 500;
        }
  
        @include small-device {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }

  .history-list {
    margin-top: 55px;
    text-align: left;
  
    .donation {
      font-size: 1em;
      margin-bottom: 20px;
  
      .date {
        width: 150px;
        display: inline-block;
        font-weight: 500;
  
        @include small-device {
          display: block;
          margin-bottom: 5px;
        }
      }

      .notation {
        padding-left: 150px;

        @include small-device {
          padding-left: 0px;
        }

        span {
          font-weight: 400;
        }

        span.not-empty {
          display: block;
        }
      }

      button {
        padding: 0px;
        text-transform: none;
        color: $link-color;
      }
    }
  }
}
